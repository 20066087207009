/*

	Para poder fazer o parallax do conteúdo, add a classe ".parallax-content" á seção pai,
	e no css do alvo da animação coloque a seguinte propriedade:

	<div class="section parallax-content">
		<div class="inner">...</div>
	</div>

	<style lang="scss">
		.section {
			.inner{
				transform: translateY( var(--parallax-y) );
			}
		}
	</style>

*/

var parallaxContent = (function() {
	var ja 			= false;
	var containers 	= document.querySelectorAll('.parallax-content');

	function parallax () {

		containers.forEach(function(container) {
			var elementOffset   = container.getAttribute('data-offset') || 'body';
			var offset 			= document.querySelector(elementOffset).getBoundingClientRect().height;
			var rect = container.getBoundingClientRect().top;

			if(midiaSize == 'xs' || midiaSize == 'sm' || midiaSize == 'md') {
				if(rect <= offset) {

					container.setAttribute('style', `--parallax-y: ${parseInt((rect - offset)/-2)}px`);

				}else{
					container.setAttribute('style', `--parallax-y: 0px`);
				}
			}else{
				if(rect <=0) {
					container.setAttribute('style', `--parallax-y: ${parseInt((rect)/-2)}px`);
				} else{
					container.setAttribute('style', `--parallax-y: 0px`);
				}
			}
		});
	}

	parallax();

	window.addEventListener('scroll', function() {
		if(ja) return;

		setTimeout(function() {
			ja = false;
		}, 100);

		parallax();
	});

})();

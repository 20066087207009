$('[data-scroll-to]').click(function(e){
	e.preventDefault();

	var target = $(this).data('scroll-to');
	var targetPosition = $(target).offset();

	if(targetPosition){
		var th = 0;

		th = $('.topo').outerHeight()

		$('body, html').animate({
			scrollTop : targetPosition.top
		}, 1000);
	}

	$('.main-navigation').removeClass('active');
	$('.bg-menu').fadeOut(400);
	$('.btn-mbl').toggleClass('active');
});
var fixScrollOnLoad = (function() {

	window.addEventListener('load', function () {
		var wl = window.location;

		if(wl.search != '') {
			var search = wl.search;
			var t = search.replace('?section=', '');
			var l = $('#'+t).offset().top;

			if(search === '?section=fale-conosco') {

				$('body, html').animate({
					scrollTop: l
				})
			}
		}
	});

})();
